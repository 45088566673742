import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AsyncSubject, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';;
import { EditorComponent, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TipoTemplate } from 'src/app/template/shared/template.model';

@Component({
  selector: 'app-editor-html-template',
  templateUrl: './editor-html-template.html',
  providers: [
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}

  ]

})
export class EditorHtmlTemplateComponent implements OnInit {
editorConfig: any;
editorContent: string ='';
@ViewChild(EditorComponent) editorComponent: EditorComponent;
// other properties
editorSubject: Subject<any> = new AsyncSubject();

   @Input() htmlAntigo:any;
   @Input() getTemplateTagsService: (params: any) => Observable<any>;
   @Input() empresas:any[];
   @Input() tpTemplate: TipoTemplate;
   @Input() objeto:any;
   @Input() postTrocarTagsHtmlService: (params: any) => Observable<any>;
   
   @Output() novoHtml = new EventEmitter<string>();



   private contentChangeSubject = new Subject<string>();

   tags:any;
  editor: any;

  ngOnInit(): void {
   
    this.setupEditor();
   
  }

  ngAfterViewInit() {



  }

  

  ngOnChanges(changes: SimpleChanges) {

    if (changes.htmlAntigo && changes.htmlAntigo.currentValue!==changes.htmlAntigo.previousValue) {
       //insere o html no editor
       this.editorContent = this.htmlAntigo
  }
  if(changes.empresas && changes.empresas.currentValue!==changes.empresas.previousValue && changes.empresas.currentValue.length>0){
   this.carregaTags( changes.empresas.currentValue);
  }

  if(changes.tpTemplate && changes.tpTemplate.currentValue!==changes.tpTemplate.previousValue && changes.tpTemplate.currentValue>0){
    this.carregaTags(this.empresas);
   }
  
}
  // Método para simular o clique no botão de impressão do TinyMCE
  triggerPrint() {
    if (this.editorComponent.editor) {
      this.editorComponent.editor.execCommand('mcePrint');
    }
  }

onEditorContentChange(event: any) {

    if(this.htmlAntigo !== event){

        if (this.containsDynamicTags(event) && this.objeto) {
            this.objeto.contratoHtmlTexto = event;
    

            this.postTrocarTagsHtmlService(this.objeto).pipe(
            map(response => response.body || [])
            )
            .subscribe(data => {
            this.editorContent = data;
            

            });
        } 

        this.editorContent = event;

    //    this.editorComponent.editor.setContent(this.editorContent);
      //  this.editorComponent.editor.execCommand('mceRepaint');
        this.novoHtml.emit(this.editorContent);

    }
}

containsDynamicTags(content: string): boolean {
    const dynamicTagPattern = /\{\{.*?\}\}/;
    return dynamicTagPattern.test(content);
  }


carregaTags(empresasCurrent) {
    if(empresasCurrent.length > 0){  
    this.getTemplateTagsService(empresasCurrent).pipe(
      map(response => response.body || []))
      .subscribe(data => {
        // Atualiza a lista de tags filtrados
        this.tags = data; 
      });
    }
  }



  onEditorInit(event: any) {
    this.editorSubject.next(event.editor);
    this.editorSubject.complete();
  }

  setupEditor() {
    this.editorConfig = {
      base_url: '/tinymce', 
      suffix: '.min',
      license_key: 'gpl',
      language: 'pt_BR',
      language_url: 'assets/tinymce/pt_BR.js',
      toolbar_mode: 'sliding',
      plugins: 'link image code table emoticons lists preview fullscreen searchreplace insertdatetime advlist quickbars',
      toolbar1: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight outdent indent | numlist bullist',
      toolbar2: 'link image table emoticons| forecolor backcolor removeformat | code fullscreen preview print  | addTagButton',
      toolbar3: 'customAlertButton',
      menubar: 'file edit view insert format tools table help',
      quickbars_selection_toolbar: 'addTagButton | bold align numlist bullist',
      quickbars_insert_toolbar: 'addTagButton | bold align numlist bullist',
      height: 600,
  
      setup: (editor) => {
        editor.on('Change', () => {
          this.onEditorContentChange(editor.getContent());
        });
  
        const flatten = (arr) => [].concat(...arr);
  
        const generateMenu = (data) => {
          // Verifica se o data está vazio ou se não existem tags
          if (Object.keys(data).length === 0) {
            return [{
              type: 'menuitem',
              text: 'Não existem tags disponíveis para esse tipo de template',
              onAction: () => {}
            }];
          }
  
          return Object.keys(data).map(key => {
            const subItems = data[key];
           
            //verificar se subItems tem a propriedade placeholder, se tiver hasSubmenu = false
            let hasSubmenu = true;
            if (subItems.hasOwnProperty('placeholder')) {
              hasSubmenu = false;
            }
  

            if (hasSubmenu) {
              // Se tiver submenu, monta o submenu normalmente
              const submenuItems = flatten(Object.keys(subItems)
                .filter(subKey => subKey !== 'nome')
                .map(subKey => {
                  const subItem = subItems[subKey];
                  if (Array.isArray(subItem) && subItem.length > 0) {
                    return subItem.map(item => ({
                      type: 'nestedmenuitem',
                      text: item.nome,
                      getSubmenuItems: () => Object.keys(item)
                        .filter(itemKey => itemKey !== 'nome')
                        .map(itemKey => ({
                          type: 'menuitem',
                          text: item[itemKey].nome,
                          onAction: () => editor.insertContent(item[itemKey].placeholder)
                        }))
                    }));
                  }
                  else if (typeof subItem === 'object' && subItem.hasOwnProperty('placeholder')) {
                    // SubItem é um menu simples
                    return {
                      type: 'menuitem',
                      text: subItem.nome,
                      onAction: () => editor.insertContent(subItem.placeholder)
                    };
                  } else if (typeof subItem === 'object' && subItem !== null) {
                    // SubItem é um submenu
                    return {
                      type: 'nestedmenuitem',
                      text: subItem.nome,
                      getSubmenuItems: () => Object.keys(subItem)
                        .filter(itemKey => itemKey !== 'nome')
                        .map(itemKey => ({
                          type: 'menuitem',
                          text: subItem[itemKey].nome,
                          onAction: () => editor.insertContent(subItem[itemKey].placeholder)
                        }))
                    };
                  }
                })
              );
  
              return {
                type: 'nestedmenuitem',
                text: subItems.nome,
                getSubmenuItems: () => submenuItems.length > 0 ? submenuItems : [{
                  type: 'menuitem',
                  text: 'Não existem tags disponíveis para esse tipo de template',
                  onAction: () => {}
                }]
              };
            } 
          });
        };

        editor.ui.registry.addMenuButton('addTagButton', {
          icon: 'addtag',
          tooltip: 'Add Tag',
          fetch: (callback) => {
            const items = generateMenu(this.tags);
            callback(items);
          }
        });
      }
    };
  }
  
  

  modifyHtmlBeforePrint(orientacaoPaisagem = false): any {
    
    let texto='';
    if (orientacaoPaisagem) {
      texto += '<style>@page { size: A4 landscape; margin: 0; }</style>';
    } else {
      texto += '<style>@page { size: A4 portrait; margin: 0; }</style>';
    }
    
    // Adiciona estilos para remover margens do corpo e da página ao imprimir
    texto += '<style>body { margin: 0; }</style>';
    texto += '<style>@media print { @page { margin: 0; } body { margin: 0; } }</style>';
    
    texto += '</head><body onload="window.print()" style="padding: 12px;">' + this.novoHtml + '</body></html>';
    return texto;




  }
  

}