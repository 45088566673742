<mat-toolbar>
  <div class="md-toolbar-tools">
    <h1 mat-dialog-title>NFS-e - {{nfseCadastroForm.value.numeroNfse}} {{statusNfse}}</h1>
    <span flex></span>
    <div class="posicionar-direita">
      <button (click)="restaurarDialog()" class="botao-header" [hidden]="!maximizado" matTooltip="Minimizar">
        <mat-icon>fullscreen_exit</mat-icon>
      </button>
      <button (click)="maximizarDialog()" class="botao-header" [hidden]="maximizado" matTooltip="Expandir">
        <mat-icon>fullscreen</mat-icon>
      </button>
      <button (click)="fecharDialog()" class="botao-header" matTooltip="Fechar">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" matTooltip="Salvar" (click)="salvar()" [disabled]="nfseCadastroForm.invalid">
    <mat-icon>save</mat-icon>
  </button>
  <button mat-raised-button (click)="enviarSefaz()" [disabled]="isDisabled || nfseCadastroForm.invalid" matTooltip="Enviar Sefaz">
    <mat-icon>send</mat-icon> Sefaz
  </button>
  <!--
  <button mat-raised-button *ngIf="nfseCadastroForm?.value?.statusProcessamento == '2'" (click)="verificarRetornoTardio()">
    <mat-icon>sync</mat-icon> Retorno Tardio
  </button>
  -->
  <button mat-raised-button *ngIf="autorizada" (click)="geraLinkImpressao()" matTooltip="Imprimir">
    <mat-icon>print</mat-icon>
  </button>
  <button mat-raised-button [matMenuTriggerFor]="opcoes" matTooltip="Opções">
    <mat-icon>more_horiz</mat-icon>
  </button>
  <mat-menu #opcoes>
    <button mat-menu-item *ngIf="nfseCadastroForm.value.nfseId > 0" (click)="modalNfseStatus()">Status</button>
    <button mat-menu-item *ngIf="nfseCadastroForm.value.nfseId > 0 && autorizada"
      (click)="cancelarNfse()">Cancelar</button>
  </mat-menu>
</div>
<div *ngIf="nfseCadastroForm.invalid" style="color: red; font-size: 12px; margin-top: 15px;">
  * Existem campos obrigatórios que ainda não foram preenchidos.
</div>
<mat-dialog-content>
  <div class="md-dialog-content">
    <br />
    <div class="row">
      <form [formGroup]="nfseCadastroForm">
        <div class="col-md-12">
          <div class="card">
            <mat-tab-group>
              <mat-tab label="Geral">
                <br />
                <div class="row">
                  <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Emitente</mat-label>
                    <mat-select formControlName="empresaId">
                      <mat-option *ngFor="let empresa of empresas" [value]="empresa.empresaId"
                        (click)="empresaSelecionada(empresa)">
                        {{empresa.nomeFantasia}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Cliente</mat-label>
                    <input type="text" matInput [matAutocomplete]="auto" formControlName="clienteAutoComplete">
                    <mat-error *ngIf="nfseCadastroForm.get('clienteAutoComplete')?.hasError('required')">
                      Cliente é um campo obrigatório.
                    </mat-error>
                    <mat-autocomplete #auto="matAutocomplete">
                      <mat-option *ngFor="let parceiro of clientes" [value]="parceiro.nomeFantasia"
                        (click)="determinaCliente(parceiro)">
                        CNPJ: {{parceiro.cnpj}} - {{parceiro.nomeFantasia}}
                      </mat-option>
                      
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <div class="row" *ngIf="nfseCadastroForm.value.nfseId > 0">
                  <fieldset [disabled]="true">
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                      <mat-label>Número do RPS</mat-label>
                      <input type="text" matInput formControlName="numeroRps">
                    </mat-form-field>
                    <mat-form-field class="form-group col-md-4 col-xs-12">
                      <mat-label>Série do RPS</mat-label>
                      <input type="text" matInput formControlName="serieRps">
                    </mat-form-field>
                  </fieldset>
                </div>
                <div class="row">
                  <mat-form-field class="form-group col-md-6">
                    <mat-label>Competência</mat-label>
                    <input matInput [matDatepicker]="picker1" formControlName="dtEmissao">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="col-md-6 col-xs-12 form-group">
                    <mat-label>Tipo RPS</mat-label>
                    <mat-select formControlName="tipoRps">
                      <mat-option [value]="1">RPS</mat-option>
                      <mat-option [value]="2">Nota Fiscal Conjugada (Mista)</mat-option>
                      <mat-option [value]="3">Cupom</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <hr>
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <h3>Local da Prestação</h3>
                  </div>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>UF</mat-label>
                    <mat-select formControlName="localPrestacaoUfId">
                      <mat-option *ngFor="let uf of ufs" [value]="uf.ufId">
                        {{uf.descUF}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="col-md-4 col-xs-12 form-group">
                    <mat-label>Município</mat-label>
                    <mat-select formControlName="localPrestacaoMunicipioId">
                      <mat-option *ngFor="let municipio of municipios" [value]="municipio.municipioId">
                        {{municipio?.descricao}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <hr>
                <br>
                <div class="row">
                  <div class="col-md-12">
                    <h3>Dados do Serviço</h3>
                  </div>
                  <div class="row">
                    <mat-form-field class="form-group col-md-12" appearance="outline">
                      <mat-label>Descrição do Serviço Prestado</mat-label>
                      <textarea matInput rows="5" placeholder="Descrição do Serviço Prestado"
                        formControlName="discriminacao"></textarea>
                        <mat-error *ngIf="nfseCadastroForm.get('discriminacao')?.hasError('required')">
                          Descrição é um campo obrigatório.
                        </mat-error>
                    </mat-form-field>
                  </div>
                  <mat-form-field class="col-md-12 col-xs-12 form-group" appearance="outline">
                    <mat-label>Código do Serviço/Atividade</mat-label>
                    <mat-select formControlName="listaServico" (selectionChange)="buscaCnaePorServico($event.value)">
                      <mat-option *ngFor="let listaServico of listaServicos" [value]="listaServico.codigo">
                        {{ listaServico.codigo }} - {{ listaServico.descricao }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="nfseCadastroForm.get('listaServico')?.hasError('required')">
                      Código do serviço é um campo obrigatório.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline" style="display:none">
                    <mat-label>Alíquota (%)</mat-label>
                    <input matInput type="number" formControlName="aliquotaIssqn">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline" style="display:none">
                    <mat-label>Código do Serviço no Município</mat-label>
                    <input type="text" matInput placeholder="Código do Serviço no Município" formControlName="cServico">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-5 col-xs-12" appearance="outline">
                    <mat-label>Valor do serviço prestado (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorServicos">
                    <mat-error *ngIf="nfseCadastroForm.get('valorServicos')?.hasError('required')">
                      Valor do serviço é um campo obrigatório.
                    </mat-error>
                    <mat-error *ngIf="nfseCadastroForm.get('valorServicos')?.hasError('min')">
                      Valor do serviço é um campo obrigatório.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Cnae</mat-label>
                    <input type="text" matInput formControlName="cnae">
                    <mat-error *ngIf="nfseCadastroForm.get('cnae')?.hasError('required')">
                      CNAE é um campo obrigatório.
                    </mat-error>
                  </mat-form-field>
                  <div class="form-group col-md-3" style="margin-top: 1%">
                    <button mat-raised-button color="primary" (click)="calculaImpostos()"
                    [disabled]="nfseCadastroForm.get('empresaId').invalid || nfseCadastroForm.get('clienteAutoComplete').invalid || nfseCadastroForm.get('listaServico').invalid || nfseCadastroForm.get('valorServicos').invalid || nfseCadastroForm.get('cnae').invalid">
                      Calcular Imposto
                    </button>
                  </div>
                  
                </div>
                <div class="row">
                  <mat-form-field class="form-group col-md-4" *ngIf="nfseCadastroForm.get('localPrestacaoUfId').value == '20'" >
                    <mat-label>Código do Serviço no Município</mat-label>
                    <input type="text" matInput placeholder="Código do Serviço no Município" formControlName="cServico">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Valor das Deduções (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorDeducoes" (blur)="calculavalorLiquido()">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Outras Retenções (R$)</mat-label>
                    <input matInput currencyMask formControlName="outrasRetencoes" (blur)="calculavalorLiquido()">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Descontos Incondicionados (R$)</mat-label>
                    <input matInput currencyMask formControlName="descontoIncondicionado"
                      (blur)="calculavalorLiquido()">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Descontos Condicionados (R$)</mat-label>
                    <input matInput currencyMask formControlName="descontoCondicionado" (blur)="calculavalorLiquido()">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>PIS (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorPis" (blur)="calculavalorLiquido()">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ impostosNfseRetencao.pisRetido ? 'PIS está retido' : 'PIS não está retido' }}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>COFINS (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorCofins" (blur)="calculavalorLiquido()">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ impostosNfseRetencao.cofinsRetido ? 'COFINS está retido' : 'COFINS não está retido' }}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>IR (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorIr" (blur)="calculavalorLiquido()">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ impostosNfseRetencao.irpjRetido ? 'IR está retido' : 'IR não está retido' }}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>INSS (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorInss" (blur)="calculavalorLiquido()">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ impostosNfseRetencao.inssRetido ? 'INSS está retido' : 'INSS não está retido' }}
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>CSLL (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorCsll" (blur)="calculavalorLiquido()">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ impostosNfseRetencao.csllRetido ? 'CSLL está retido' : 'CSLL não está retido' }}
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Base de Cálculo (R$)</mat-label>
                    <input matInput currencyMask formControlName="baseCalculo">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Valor Líquido da Nota (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorLiquidoNfse">
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-md-6 col-xs-12 form-group" appearance="outline">
                    <mat-label>ISS Retido</mat-label>
                    <mat-select formControlName="issRetido" (blur)="calculavalorLiquido()">
                      <mat-option [value]="1">Sim</mat-option>
                      <mat-option [value]="2">Não</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Valor do ISS (R$)</mat-label>
                    <input matInput currencyMask formControlName="valorIss">
                    <mat-hint *ngIf="nfseCadastroForm.value.nfseId > 0">
                      {{ nfseCadastroForm.value.issRetido == 1 ? 'ISS está retido' : 'ISS não está retido' }}
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-3 col-xs-12" appearance="outline">
                    <mat-label>Valor do ISS Retido (R$): {{ nfseCadastroForm.value.valorIssRetido }}</mat-label>
                    <input matInput currencyMask formControlName="valorIssRetido">
                  </mat-form-field>
                </div>
              </mat-tab>
              <mat-tab label="Contrução Civil">
                <br />
                <div class="row">
                  <mat-form-field class="form-group col-md-6 col-xs-12" appearance="outline">
                    <mat-label>Código A.R.T.</mat-label>
                    <input type="text" matInput formControlName="codArt">
                  </mat-form-field>
                  <mat-form-field class="form-group col-md-6 col-xs-12" appearance="outline">
                    <mat-label>Código da Obra</mat-label>
                    <input type="text" matInput formControlName="codObra">
                  </mat-form-field>
                </div>
              </mat-tab>
              <mat-tab label="NFS-e">
                <br />
                <div class="row">
                  <div class="col-md-6">
                    <label for="NumeroNFSe">Número NFS-e:</label>
                    <label for="NumeroNFSe">{{nfseCadastroForm.value.numeroNfse}}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="codigoVerificacao">Código Verificador NFS-e:</label>
                    <label for="codigoVerificacao">{{nfseCadastroForm.value.codigoVerificacao}}</label>
                  </div>
                </div>
                <div class="row" *ngIf="nfseCadastroForm.value.statusProcessamento > 99">
                  <div class="col-md-6">
                    <div class="col-md-6">
                      <label for="dataEmissaoNfse">Data Emissão NFS-e:</label>
                      <label for="dataEmissaoNfse">{{nfseCadastroForm.value.dataEmissaoNfse |
                        date:'dd/MM/yyyy'}}</label>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </form>
    </div>
  </div>
</mat-dialog-content>